<template>
    <div class="rounded-lg shadow ring-1 ring-black ring-opacity-5">
        <table
            v-if="quota"
            aria-label="Quotas"
            class="table-default text-xs condensed small"
        >
            <thead>
                <tr>
                    <th class="text-left">
                        Type
                    </th>
                    <th>Utilisés</th>
                    <th>Disponible</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span v-tooltip="'Congés\n+Absences Justifiées déclaration sur l\'honneur max 1 jour\n+ Absences Justifiées certificats médicaux max 1j'">Absences Autorisées</span></td>
                    <td>{{ quota.autorizedAbsences.used }}</td>
                    <td>
                        <span :class="{ 'text-red-500': quota.autorizedAbsences.remaining <= 0 }">
                            {{ quota.autorizedAbsences.remaining }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Congés annuels</td>
                    <td>{{ quota.yearly.used }}</td>
                    <td>
                        <span :class="{ 'text-red-500': quota.yearly.remaining <= 0 }">
                            {{ quota.yearly.remaining }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Absences justifiées<br>(déclaration sur l'honneur max 1 jour)</td>
                    <td>{{ quota.quaterlySworn.used }}</td>
                    <td>
                        <span :class="{ 'text-red-500': quota.quaterlySworn.remaining <= 0 }">
                            {{ quota.quaterlySworn.remaining }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import RegistrationRepository from '~/repositories/Creche/RegistrationRepository.js'

export default {
    props: {
        registration: {
            type: Object,
            required: true
        },
        day: {
            type: [String, Object, Date],
            default: null
        }
    },

    data () {
        return {
            quota: null
        }
    },

    created () {
        this.getQuota()
    },

    methods: {
        getQuota () {
            if (this.registration) {
                RegistrationRepository.quota(this.registration.uuid, {
                    date: this.day || dayjs().format('YYYY-MM-DD')
                })
                    .then((response) => {
                        this.quota = response
                    })
                    .catch(error => this.pageError(error))
            }
        }
    }
}
</script>
